.Container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto auto;
}

.WelcomeSubHeading {
  padding-top: 2rem;
  text-align: start;
  line-height: 40px;
}


.WelcomeHeading {
  padding-top: 0.5rem;
  text-align: start;
  line-height: 40px;
  font-weight: bold;
  padding-bottom: 0.2rem;
  font-size: 2.25rem;
}

.WelcomeDescription {
  text-align: start;
  font-weight: 200;
  line-height: 40px;
  padding-bottom: 1rem;
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.LoginContainer {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.LoginFormContainer {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.UsernameInput {
  width: 100%;
  display: block;
  padding: 0.8rem;
  font-size: 1rem;
  border: 0.5px solid #aaa;
  border-radius: 5px;
}

.Error {
  padding-bottom: 1rem;
  color: red;
}

.PasswordInput {
  width: 100%;
  margin-top: 1rem;
  display: block;
  padding: 0.8rem;
  font-size: 1rem;
  border: 0.5px solid #aaa;
  border-radius: 5px;
}

.LoginButton {
  padding: 0.8rem;
  margin-top: 1rem;
  width: 100%;
  background-color: #146ef5;
  border: 2px solid rgb(146, 110, 245, 1);
  border-radius: 5px;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
}

.LoginButton:hover {
  opacity: 0.9;
  font-size: 1.05rem;
}

.LoginButton:active {
  transform: scale(0.95);
}

.LoginButton:disabled {
  background-color: grey;
  border: 2px solid grey;
  transform: unset;
  cursor: not-allowed;
}

.Bg {
  height: 100%;
  width: 60%;
  background-image: url('../../assets/welcome4.svg');
  background-size: cover;
  background-position: center;
}
