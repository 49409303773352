.Heading {
  font-size: 1.5rem;
  color: #202328;
  font-weight: bold;
}

.Container {
  margin-bottom: 3rem;
}

.Description {
  padding-top: 0.6rem;
}
