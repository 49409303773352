@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  /* overflow: none; */
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
html {
  height: 100%;
}
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans';
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  margin-top: 10px;
  background-color: #333;
  color: white;
  padding: 8px;
  border-radius: 4px;
}

.fallback {
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
  p {
    line-height: 3rem;
    text-align: center;
    font-weight: 200;
  }
}

@media (max-width: 650px) {
  .fallback {
    display: flex;
  }

  .original {
    display: none;
  }
}
