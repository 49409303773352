.Heading {
  font-family: 'DM Sans';
  font-size: 1.5rem;
  color: #202328;
  font-weight: bold;
  margin-bottom: 1rem;
}

.Container {
  width: 50dvw;
  min-width: 20rem;
  max-width: 20rem;
  margin-bottom: 3rem;
  margin-right: 5rem;
}

.Count {
  padding: 0.4rem 1rem;
  background-color: #dcebfe;
  color: #146ef5;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: bolder;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 4px;
  margin-left: 20px;
}

.SearchInput {
  padding: 0.6rem;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #aaa;
}

.VersionsList {
  max-height: 17rem;
  overflow: scroll;
  padding-right: 1rem;
}

.VersionRow {
  margin-top: 1rem;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.EmptyListContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
