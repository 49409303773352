.HeaderContainer {
  background-color: #202328;
  height: 6rem;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
}

.LogoutButton, .RefreshButton {
  cursor: pointer;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  text-align: center;
  display: flex;
  margin-right: 1rem;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  z-index: 1;
  background-color: transparent;
}

.LogoutButton:hover {
  color: white;
  transform: scale(1.1);
}

.LogoContainer:hover {
  opacity: 0.8;
  cursor: pointer;
}

.RefreshContainer img {
  animation: none; 
}

.RefreshContainer button:disabled {
  cursor: not-allowed; 
}
