.Heading {
  font-family: 'DM Sans';
  font-size: 1.5rem;
  color: #202328;
  font-weight: bold;
}

.Container {
  margin-bottom: 3rem;
  margin-right: 2rem;
}

.TypeButtons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0rem 0rem 0rem;
}

.TypeButton {
  margin-bottom: 0.5rem;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  color: #000000;
  box-shadow: none;
  border: 1px solid #146ef5;
  font-family: 'DM Sans';
  font-size: 0.875rem;
  margin-right: 0.6rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.TypeButton:hover {
  cursor: pointer;
  opacity: 0.9;
}

.Banner {
  display: inline-flex;
  padding: 1rem 2rem 1rem 1rem;
  border: 1px solid #de8787;
  border-radius: 5px;
  border-left: 5px solid #de8787;
  margin: 1rem 0rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.List {
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: 35rem;
  overflow: scroll;
}

.Row {
  border-bottom: 0.6px solid #ddd;
  justify-content: space-between;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Row:hover {
  transform: scale(0.98);
  font-weight: bold;
  cursor: pointer;
}

.ListHeading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.8px solid #ddd;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InfoNote {
  margin: 1rem 0rem;
  border: 1.5px solid rgb(20, 110, 245);
  font-size: 1rem;
  font-weight: 400;
  padding: 10px;
  top: 3rem;
  left: 0px;
  border-radius: 5px;
  line-height: 25px;
  border-left: 5px solid rgb(20, 110, 245);
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.1);
  position: sticky;
  background-color: white;
  color: black;
  z-index: 999;
  /* opacity: 0; */
}
