.Loader {
  border: 4px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
  display: block;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.LoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: auto auto;
}

.WelcomeHeading {
  padding-top: 2rem;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 2.25rem;
}

.WelcomeDescription {
  padding-top: 1rem;
  text-align: center;
  line-height: 30px;
  opacity: 0.7;
  font-size: 1rem;
}

.WelcomeContainer {
  display: flex;
  /* margin-top: 5rem; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  flex-grow: 1;
}
