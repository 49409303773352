.DashboardContainer {
  width: 100%;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  height: 100%;
}

.InfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.Loader {
  border: 4px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
  display: block;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.ErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.Heading {
  padding-top: 2rem;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 2.25rem;
}

.Description {
  padding-top: 1rem;
  text-align: center;
  line-height: 30px;
  opacity: 0.7;
  font-size: 1rem;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.StartOverButton {
  padding: 0.8rem;
  margin-top: 1rem;
  width: 100%;
  background-color: #146ef5;
  border: 2px solid rgb(146, 110, 245, 1);
  border-radius: 5px;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
}