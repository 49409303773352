.IntegrationInfoContainer {
  height: 100vh;
  overflow: scroll;
  width: 100%;
  padding: 3rem;
  padding-bottom: 10rem;
}

.IntegrationHeading {
  font-size: 2.25rem;
  font-family: 'DM Sans';
  font-weight: bold;
  margin-bottom: 1rem;
  /* margin-top: 1rem; */
}

.IntegrationDescription {
  font-size: 1rem;
  font-family: 'DM Sans';
  /* text-align: justify; */
}

.IntegrationHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

p {
  line-height: 30px;
}

.InfoChip {
  padding: 0.3rem 1rem;
  margin-right: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
  flex: 0 0 auto;
  font-size: 0.9rem;
  margin-bottom: 0.6rem;
}

.IntegrationQuickLinks {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.QuickLink {
  margin-bottom: 1rem;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  white-space: nowrap;
  height: 2.5rem;
  color: #ffffff;
  box-shadow: none;
  border: 1px solid #146ef5;
  font-family: 'DM Sans';
  font-weight: bold;
  font-size: 0.875rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.QuickLink:active {
  transform: scale(0.95);
}

.QuickLink:hover {
  cursor: pointer;
  opacity: 0.9;
}

.NoDataImage {
  height: 18dvw;
  width: 18dvw;
  margin-right: 5rem;
}

@media (max-width: 1240px) {
  .NoDataImage:first-child {
    display: none;
  }
  .NoDataImage:nth-child(2) {
    display: none;
  }
}

.WelcomeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem;
  padding-top: 5rem;
}

.IntegrationNotSelected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.IntegrationInfo {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.WelcomeHeading {
  padding-top: 2rem;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 2.25rem;
}

.WelcomeDescription {
  padding-top: 0.8rem;
  text-align: center;
  line-height: 30px;
  opacity: 0.7;
  font-size: 1rem;
}

.WelcomeSubHeading {
  padding-top: 2rem;
  text-align: center;
  line-height: 30px;
  font-weight: 300;
  font-size: 1.2rem;
}

.WelcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.ChipsContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.Container {
  margin-right: 3rem;
}

@media (max-width: 1110px) {
  .IntegrationHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.GoBack {
  margin-bottom: 1rem;
}

.GoBack:hover {
  cursor: pointer;
}



.TypeButtons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0rem 0rem 0rem;
}

.TypeButton {
  margin-bottom: 0.5rem;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  color: #000000;
  box-shadow: none;
  border: 1px solid #146ef5;
  font-family: 'DM Sans';
  font-size: 0.875rem;
  margin-right: 0.6rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.TypeButton:hover {
  cursor: pointer;
  opacity: 0.9;
}