@font-face {
  font-family: 'DM Sans';
  src: url('./fonts/DMSans.ttf');
}

.ErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto auto;
}

.Heading {
  padding-top: 2rem;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 2.25rem;
}

.Description {
  padding-top: 1rem;
  text-align: center;
  line-height: 30px;
  opacity: 0.7;
  font-size: 1rem;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.intz-flex {
  display: flex;
}

.intz-padding-top-s {
  padding-top: 1rem;
}

.intz-justify-start {
  justify-content: flex-start;
}

.intz-align-start {
  align-items: flex-start;
}

.intz-flex-column {
  flex-direction: column;
}

.intz-margin-left-s {
  margin-left: 1rem;
}

.intz-font-bold {
  font-weight: bold;
}

.intz-margin-top-xs {
  margin-top: 0.4rem;
}
